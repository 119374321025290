<template>
	<e-select
		track-by="contact_id"
		v-model="searchValue"
		:options="options"
		:placeholder="$t('global.ecrire_pour_rechercher')"
		:loading="isLoading"
		:sortable="false"
		:internal-search="false"
		@search-change="onSearch"
		@select="searchContact"
		@input="searchContact"
		ref="input"
	>
		<template slot="singleLabel" slot-scope="{ option }">{{ option.contact_firstname }} {{ option.contact_lastname }} <template v-if="option.phone_combine">({{ option.phone_combine }})</template></template>
		<template slot="option" slot-scope="{ option }">{{ option.contact_firstname }} {{ option.contact_lastname }} <template v-if="option.phone_combine">({{ option.phone_combine }})</template></template>
		<template slot="noOptions">{{ $t('global.write_to_search') }}</template>
		<template slot="noResult">{{ $t('global.no_result') }}</template>
	</e-select>
</template>

<script>
import _debounce from 'lodash/debounce'
import Contact from '@/mixins/Contact'
import Navigation from '@/mixins/Navigation'
import ContactTransformer from '@/assets/js/dexie/transformers/ContactTransformer.js'

export default {
	name: 'SearchContact',
	mixins: [Contact, Navigation],
	props: ['preselected', 'presearch', 'search_value', 'operators'],
    data() {
        return {
			options: [],
			searchValue: null,
			isLoading: false,
			debouncedSearch: _debounce(this.search, 300),
		}
	},
	created() {
		this.init_component()
	},
	methods: {
		async init_component() {
			if(this.preselected) {
				this.options = [this.preselected]
				this.searchValue = this.preselected
			}
			else if(this.presearch) {
				this.searchValue = this.presearch
			}
			else if(this.search_value) {
				this.searchValue = {contact_firstname: this.search_value}
				this.onSearch(this.search_value)
			}

			if(this.operators) {
				this.options = await this.getAllContact(true)
			}
		},
		onSearch(search_val) {
			if(search_val) {
				this.$emit("update:search_value", search_val)
			}
			this.debouncedSearch(search_val)
		},
		async search(search_val) {
			this.isLoading = true

			if(search_val == '') {
				this.isLoading = false
				return false
			}

			if(this.$storage.db.contact) {
				let contact = await this.$storage.db.t('contact').then(table => {
					return table.where({
						contact_archive: 0
					})
				})
				.then(col => {
					return col.transform(new ContactTransformer('withPhone'))
				})
				.then(col => {
					return col.filter(elem => {
						var regex = new RegExp(search_val, 'gi');
						return (elem.contact_lastname && regex.test(elem.contact_lastname.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))) 
						|| (elem.contact_firstname && regex.test(elem.contact_firstname.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))) 
						|| regex.test(elem.phone_combine)
					})
				})

				this.options = contact.sortBy('contact_lastname')
			}

			this.isLoading = false
		},
		onFocus() {
			this.$refs.input.activate()
			this.$refs.input.$refs.search.click()
		},
		async searchContact(option) {
			if(option != null) {
				// this.options = [option]
				this.searchValue = {contact_lastname: option.contact_lastname, contact_firstname: option.contact_firstname}
				this.$emit("update:contact_selected", option)
			}
			else {
				this.$emit("update:contact_selected", {})
			}
		},
	},
	watch: {
		preselected(val){
			if(val != undefined) {
				this.options = [val]
				this.searchValue = val
			}
		}, 
		presearch(val) {
			if(val) {
				this.searchValue = {contact_lastname: val.contact_lastame, contact_firstname: val.contact_firstname}
				this.debouncedSearch(val)
			}
		}
	}
}
</script>
